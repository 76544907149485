import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo/seo";
import Layout from "../containers/layout";

// export const query = graphql`
//   query ArchivePageQuery {
//     projects: allSanitySampleProject(
//       limit: 12
//       sort: { fields: [publishedAt], order: DESC }
//       filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
//     ) {
//       edges {
//         node {
//           id
//           basicImage {
//             asset {
//               _id
//             }
//             alt
//           }
//           title
//           _rawExcerpt
//           slug {
//             current
//           }
//         }
//       }
//     }
//   }
// `;

const ArchivePage = props => {
 
  return (
    <Layout>
      <SEO title="Archive" />
    </Layout>
  );
};

export default ArchivePage;
